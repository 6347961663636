// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- ROUTES ADMINISTRATEURS  ---------------------------------------*
  // *===============================================---*
  {
    path: '/espace-super-admin',
    redirect: '/espace-super-admin/dashboard',
    component: () => import('@/layouts/espace-super-admin/SuperAdminLayout.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.authStore.role === 'Super administrateur') next()
      else next({ name: 'error-401' })
    },
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: true,
    },
    children: [
      // Dashboard
      {
        path: '/espace-super-admin/dashboard',
        name: 'espace-super-admin.dashboard',

        component: () => import('@/views/app/espace-super-admin/Home.vue'),

        meta: {
          pageTitle: 'Tableau de bord',
          redirectIfLoggedIn: true,

        },
      },
      // Gestion des catégories de sociétés
      {
        path: '/espace-super-admin/categorie-societe',
        name: 'espace-super-admin.liste.categorie.societe',

        component: () => import('@/views/app/espace-super-admin/parametres/CategorieSociete.vue'),

        meta: {
          pageTitle: 'Catégories de société',
          redirectIfLoggedIn: true,

        },
      },
      // Gestion des sociétés
      {
        path: '/espace-super-admin/societe',
        name: 'espace-super-admin.liste.societe',

        component: () => import('@/views/app/espace-super-admin/societes/Index.vue'),

        meta: {
          pageTitle: 'Sociétés',
          redirectIfLoggedIn: true,

        },
      },
      {
        path: '/espace-super-admin/:id/detail-societe',
        name: 'espace-super-admin.detail-societe',

        component: () => import('@/views/app/espace-super-admin/societes/Detail.vue'),

        meta: {
          pageTitle: 'Détail Société',
          redirectIfLoggedIn: true,

        },
      },
      // Gestion des copropriétés
      {
        path: '/espace-super-admin/copropriete',
        name: 'espace-super-admin.liste.copropriete',

        component: () => import('@/views/app/espace-super-admin/coproprietes/Index.vue'),

        meta: {
          pageTitle: 'Copropriétés',
          redirectIfLoggedIn: true,

        },
      },
      {
        path: '/espace-super-admin/:id/detail-copropriete',
        name: 'espace-super-admin.detail-copropriete',

        component: () => import('@/views/app/espace-super-admin/coproprietes/Detail.vue'),

        meta: {
          pageTitle: 'Détail Copropriété',
          redirectIfLoggedIn: true,

        },
      },

      // Gestion des modèles économiques
      {
        path: '/espace-super-admin/modele-economique',
        name: 'espace-super-admin.modele.economique',

        component: () => import('@/views/app/espace-super-admin/parametres/ModeleEconomique.vue'),

        meta: {
          pageTitle: 'Modèles économiques',
          redirectIfLoggedIn: true,
        },
      },
      // Gestion des rôles
      {
        path: '/espace-super-admin/role',
        name: 'espace-super-admin.role',

        component: () => import('@/views/app/espace-super-admin/parametres/Role.vue'),

        meta: {
          pageTitle: 'Rôles',
          redirectIfLoggedIn: true,
        },
      },

      // Gestion des utilisateurs
      {
        path: '/espace-super-admin/utilisateur',
        name: 'espace-super-admin.liste.utilisateurs',

        component: () => import('@/views/app/espace-super-admin/utilisateurs/Index.vue'),

        meta: {
          pageTitle: 'Utilisateurs',
          redirectIfLoggedIn: true,
        },
      },
      {
        path: '/espace-super-admin/:id/detail-utilisateur',
        name: 'espace-super-admin.detail-utilisateur',

        component: () => import('@/views/app/espace-super-admin/utilisateurs/Detail.vue'),

        meta: {
          pageTitle: 'Profil Utilisateur',
          redirectIfLoggedIn: true,
        },
      },
      // Gestion des rôles
      {
        path: '/espace-super-admin/type-contrat',
        name: 'espace-super-admin.type.contrat',

        component: () => import('@/views/app/espace-super-admin/parametres/TypeContrat.vue'),

        meta: {
          pageTitle: 'Type contrat',
          redirectIfLoggedIn: true,
        },
      },
      // Gestion des catégories de frais
      {
        path: '/espace-super-admin/categories-de-frais',
        name: 'espace-super-admin.categorie.frais',

        component: () => import('@/views/app/espace-super-admin/parametres/CategoryFrais.vue'),

        meta: {
          pageTitle: 'Catégories de frais',
          redirectIfLoggedIn: true,
        },
      },
    ],
  },
]
