import Vue from 'vue'
import VueRouter from 'vue-router'
import superadmin from './routes/super-admin'
import adminsyndic from './routes/admin-syndic'
import coproprietaire from './routes/coproprietaire'
import locataire from './routes/locataire'
import planificateur from './routes/planificateur'
import prestatairedeservice from './routes/prestataire-de-service'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...superadmin,
    ...adminsyndic,
    ...coproprietaire,
    ...locataire,
    ...planificateur,
    ...prestatairedeservice,

    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        pageTitle: 'Se connecter',
      },
    },
    {
      path: '/login-admin',
      name: 'login-admin',
      component: () => import('@/views/LoginSuperAdmin.vue'),
      meta: {
        pageTitle: 'Se connecter',
      },
    },
    
    {
      path: '/error-401',
      name: 'error-401',
      component: () => import('@/views/error/Error401.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading

  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
