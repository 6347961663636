/* eslint-disable no-param-reassign */
import axios from 'axios'
import { $themeConfig } from '@themeConfig'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

// eslint-disable-next-line global-require
const { API_URL } = $themeConfig.app

const axiosClient = axios.create({
  baseURL: API_URL,
})

axiosClient.interceptors.request.use(
  config => {
    // eslint-disable-next-line no-prototype-builtins
    if (store.state.authStore.hasOwnProperty('token')) {
      config.headers.Authorization = `Bearer ${store.state.authStore.token}`
    }
    config.headers.withCredentials = true
    return config
  },
  error => Promise.reject(error),

)

axiosClient.interceptors.response.use(

  res => res,
  async err => {
    const originalConfig = err.config
    const currentRouteName = router.currentRoute

    // await router.push({ name: 'authentification', replace: true })
    // eslint-disable-next-line no-mixed-operators, no-prototype-builtins
    if (originalConfig.url !== '/login' && currentRouteName.meta.hasOwnProperty('requiresAuth') && currentRouteName.meta.requiresAuth && err.response) {
      // Access Token was expired
      // eslint-disable-next-line no-underscore-dangle
      if (err.response.status === 401 && !originalConfig._retry) {
        // eslint-disable-next-line no-underscore-dangle
        // store.commit('initializeAuth')

        // eslint-disable-next-line no-underscore-dangle
        originalConfig._retry = false
      }
    }

    return Promise.reject(err)
  },
)

export default axiosClient
