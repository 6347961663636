import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import 'leaflet-geosearch/dist/geosearch.css'

import { localize } from 'vee-validate'
import fr from 'vee-validate/dist/locale/fr.json'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// Axios Mock Adapter
import '@/@fake-db/db'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.prototype.configPhoneNumber = {
  countrySelectorLabel: 'Code pays',
  countrySelectorError: 'Choisir un pays',
  phoneNumberLabel: 'Numéro de téléphone',
  example: 'Exemple :',
}

// Vue.prototype.jQuery = jQuery

// Composition API
Vue.use(VueCompositionAPI)

// import assets styles by vuex
require('@core/scss/core.scss')
require('@/assets/scss/style.scss')
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

localize('fr', fr)
Vue.config.productionTip = true

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
