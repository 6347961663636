// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import axiosClient from '@/helpers/axios'
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'

const authStore = {
  state: {
    authenticated: false,
    user: null,
    token: null,
    role: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = JSON.stringify(user)
      state.authenticated = true
    },
    async initializeAuth(state) {
      // Initialiser l'authentification si l'utilisateur était bien connecté
      try {
        const response = await axiosClient.get('/user')
        state.authenticated = true
        state.user = JSON.stringify(response.data)
      } catch (error) {
        if (state.token !== null) {
          // eslint-disable-next-line no-alert
          Swal.fire({
            title: 'Oups!',
            text: 'Session expirée',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger btn-sm',
            },
            confirmButtonText: 'Ok',
          })

          state.token = null
          state.authenticated = false
          state.user = {}
          localStorage.removeItem('vuex')
          localStorage.clear()
          await router.push({ name: 'login', replace: true })
          console.clear()
        }
      }
    },
    initializeRole(state, value) {
      state.role = value
    },

    userUnauthenticated(state) {
      Swal.fire({
        title: 'Oups!',
        text: 'Session expirée',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-danger btn-sm',
        },
        confirmButtonText: 'Ok',
      })

      state.token = null
      state.authenticated = false
      state.user = {}
      localStorage.removeItem('vuex')
      localStorage.clear()
      router.push({ name: 'login', replace: true })
      console.clear()
    },

  },
}

export default authStore
