// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- ROUTES PRESTATAIRE DE SERVICE ---------------------------------------*
  // *===============================================---*
  {
    path: '/espace-prestataire-de-service',
    redirect: '/espace-prestataire-de-service/dashboard',
    component: () => import('@/layouts/espace-prestataire-de-service/PanificateurLayout.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.authStore.role === 'Prestataire de service') next()
      else next({ name: 'error-401' })
    },
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: true,
    },
    children: [
      // Dashboard
      {
        path: '/espace-prestataire-de-service/dashboard',
        name: 'espace-prestataire-de-service.dashboard',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-planificateur-des-taches/Home.vue'),

        meta: {
          pageTitle: 'Tableau de bord',
          redirectIfLoggedIn: true,

        },
      },
      // Tâches d'entretien planifié
      {
        path: '/espace-prestataire-de-service/taches-entretien-planifie',
        name: 'espace-prestataire-de-service.taches-entretien-planifie',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-prestataire-de-service/operations/TachesEntretienPlanifie.vue'),

        meta: {
          pageTitle: 'Taches d\'entretien planifié',
          redirectIfLoggedIn: true,
        },
      },

    ],
  },
]
