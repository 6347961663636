// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- ROUTES COPROPRIETAIRES ---------------------------------------*
  // *===============================================---*
  {
    path: '/espace-coproprietaire',
    redirect: '/espace-coproprietaire/dashboard',
    component: () => import('@/layouts/espace-coproprietaire/CoproprietaireLayout.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.authStore.role === 'Copropriétaire') next()
      else next({ name: 'error-401' })
    },
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: true,
    },
    children: [
      // Dashboard
      {
        path: '/espace-coproprietaire/dashboard',
        name: 'espace-coproprietaire.dashboard',

        component: () => import('@/views/app/espace-coproprietaire/Home.vue'),

        meta: {
          pageTitle: 'Tableau de bord',
          redirectIfLoggedIn: true,

        },
      },
      // Propriétés
      {
        path: '/espace-coproprietaire/proprietes',
        name: 'espace-coproprietaire.proprietes',

        component: () => import('@/views/app/espace-coproprietaire/proprietes/Index.vue'),

        meta: {
          pageTitle: 'Gestion des biens immobiliers',
          redirectIfLoggedIn: true,

        },
      },
      {
        path: '/espace-coproprietaire/:id/detail-propriete',
        name: 'espace-coproprietaire.detail-propriete',

        component: () => import('@/views/app/espace-coproprietaire/proprietes/Detail.vue'),

        meta: {
          pageTitle: 'Détail du bien immobilier',
          redirectIfLoggedIn: true,

        },
      },
      // Demande de logement
      {
        path: '/espace-coproprietaire/demande-de-logement',
        name: 'espace-coproprietaire.demande-de-logement',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-coproprietaire/logements/Index.vue'),

        meta: {
          pageTitle: 'Gestion des demandes',
          redirectIfLoggedIn: true,

        },
      },
      // Modifier Villa
      {
        path: '/espace-coproprietaire/:id/modifier-villa',
        name: 'espace-coproprietaire.modifier-villa',

        component: () => import('@/views/app/espace-coproprietaire/proprietes/ModifyVilla.vue'),

        meta: {
          pageTitle: 'Modification d\'une villa',
          redirectIfLoggedIn: true,

        },
      },
      // Modifier Immeuble
      {
        path: '/espace-coproprietaire/:id/modifier-immeuble',
        name: 'espace-coproprietaire.modifier-immeuble',

        component: () => import('@/views/app/espace-coproprietaire/proprietes/ModifyImmeuble.vue'),

        meta: {
          pageTitle: 'Modification d\'un immeuble',
          redirectIfLoggedIn: true,

        },
      },
      // Gestion des contrats
      {
        path: '/espace-coproprietaire/contrats',
        name: 'espace-coproprietaire.contrats',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-coproprietaire/contrats/Index.vue'),

        meta: {
          pageTitle: 'Configuration des contrats',
          redirectIfLoggedIn: true,

        },
      },
      // Gestion des contrats achat vente
      {
        path: '/espace-coproprietaire/contrats-achat-vente',
        name: 'espace-coproprietaire.contrats-achat-vente',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-coproprietaire/contrats/IndexAchatVente.vue'),

        meta: {
          pageTitle: 'Configuration des contrats achat-vente',
          redirectIfLoggedIn: true,

        },
      },
      {
        path: '/espace-coproprietaire/:id/generer-contrat',
        name: 'espace-coproprietaire.generer-contrat',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-coproprietaire/contrats/GenererContrat.vue'),

        meta: {
          pageTitle: 'Générer un contrat',
          redirectIfLoggedIn: true,

        },
      },
      {
        path: '/espace-coproprietaire/:id/modifier-contrat',
        name: 'espace-coproprietaire.modifier-contrat',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-coproprietaire/contrats/ModifierContrat.vue'),

        meta: {
          pageTitle: 'Modifier un contrat',
          redirectIfLoggedIn: true,

        },
      },
      {
        path: '/espace-coproprietaire/:id/modifier-contrat-vente',
        name: 'espace-coproprietaire.modifier-contrat-vente',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-coproprietaire/contrats/ModifierContratVente.vue'),

        meta: {
          pageTitle: 'Modifier un contrat',
          redirectIfLoggedIn: true,

        },
      },
      {
        path: '/espace-coproprietaire/:id/detail-contrats',
        name: 'espace-coproprietaire.detail-contrats',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-locataire/contrats/DetailContrat.vue'),

        meta: {
          pageTitle: 'Configuration des contrats',
          redirectIfLoggedIn: true,

        },
      },
      {
        path: '/espace-coproprietaire/:id/detail-contrats-achat-vente',
        name: 'espace-coproprietaire.detail-contrats-achat-vente',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-locataire/contrats/DetailContratAchatVente.vue'),

        meta: {
          pageTitle: 'Configuration des contrats',
          redirectIfLoggedIn: true,

        },
      },
      // Gestion des factures
      {
        path: '/espace-coproprietaire/facture-de-loyer',
        name: 'espace-coproprietaire.facture-de-loyer',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-coproprietaire/factures/Index.vue'),
        meta: {
          pageTitle: 'Mes factures',
          redirectIfLoggedIn: true,
        },
      },
      // Gestion des factures frais copropriété
      {
        path: '/espace-coproprietaire/frais-de-copropriete',
        name: 'espace-coproprietaire.frais-de-copropriete',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-coproprietaire/factures/FraisDeCopropriete.vue'),
        meta: {
          pageTitle: 'Frais de copropriété',
          redirectIfLoggedIn: true,
        },
      },
      // Gestion des factures frais copropriété
      {
        path: '/espace-coproprietaire/factures-de-copropriete',
        name: 'espace-coproprietaire.facture-de-copropriete',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-coproprietaire/factures/FactureCopropriete.vue'),
        meta: {
          pageTitle: 'Facture de copropriété',
          redirectIfLoggedIn: true,
        },
      },
      // // Détail utilisateur
      // {
      //   path: '/espace-coproprietaire/:id/detail-utilisateur',
      //   name: 'espace-coproprietaire.detail-utilisateur',

      //   component: () => import('@/views/app/espace-coproprietaire/utilisateurs/Detail.vue'),

      //   meta: {
      //     pageTitle: 'Profil utilisateur',
      //     redirectIfLoggedIn: true,

      //   },
      // },
      //   // Copropriété
      //   {
      //     path: '/espace-coproprietaire/copropriete',
      //     name: 'espace-coproprietaire.copropriete',

      //     component: () => import('@/views/app/espace-coproprietaire/coproprietes/Index.vue'),

      //     meta: {
      //       pageTitle: 'Ma copropriété',
      //       redirectIfLoggedIn: true,

    //     },
    //   },
    ],
  },
]
