// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- ROUTES PLANIFICATEURS ---------------------------------------*
  // *===============================================---*
  {
    path: '/espace-planificateur',
    redirect: '/espace-planificateur/dashboard',
    component: () => import('@/layouts/espace-planificateur-des-taches/PanificateurLayout.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.authStore.role === 'Planificateur de tâches') next()
      else next({ name: 'error-401' })
    },
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: true,
    },
    children: [
      // Dashboard
      {
        path: '/espace-planificateur/dashboard',
        name: 'espace-planificateur.dashboard',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-planificateur-des-taches/Home.vue'),

        meta: {
          pageTitle: 'Tableau de bord',
          redirectIfLoggedIn: true,

        },
      },
      // Partie commune
      {
        path: '/espace-planificateur/planifier-taches',
        name: 'espace-planificateur.planifier-taches',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-planificateur-des-taches/operations/PartieCommune.vue'),

        meta: {
          pageTitle: 'Planification de tâches',
          redirectIfLoggedIn: true,
        },
      },

    ],
  },
]
