// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- ROUTES COPROPRIETAIRES ---------------------------------------*
  // *===============================================---*
  {
    path: '/espace-locataire',
    redirect: '/espace-locataire/dashboard',
    component: () => import('@/layouts/espace-locataire/LocataireLayout.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.authStore.role === 'Locataire') next()
      else next({ name: 'error-401' })
    },
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: true,
    },
    children: [
      // Dashboard
      {
        path: '/espace-locataire/dashboard',
        name: 'espace-locataire.dashboard',

        component: () => import('@/views/app/espace-locataire/Home.vue'),

        meta: {
          pageTitle: 'Tableau de bord',
          redirectIfLoggedIn: true,

        },
      },

      // Contrats
      {
        path: '/espace-locataire/locations',
        name: 'espace-locataire.locations',

        component: () => import('@/views/app/espace-locataire/locations/Index.vue'),

        meta: {
          pageTitle: 'Gestion des locations',
          redirectIfLoggedIn: true,

        },
      },

      // Gestion des contrats
      {
        path: '/espace-locataire/contrats',
        name: 'espace-locataire.contrats',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-locataire/contrats/Index.vue'),

        meta: {
          pageTitle: 'Mes contrats',
          redirectIfLoggedIn: true,

        },
      },
      // Gestion des contrats achat vente
      {
        path: '/espace-locataire/contrats-achat-vente',
        name: 'espace-locataire.contrats-achat-vente',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-locataire/contrats/IndexAchatVente.vue'),

        meta: {
          pageTitle: 'Détail de contrat achat-vente',
          redirectIfLoggedIn: true,

        },
      },
      {
        path: '/espace-locataire/:id/detail-contrats',
        name: 'espace-locataire.detail-contrats',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-locataire/contrats/DetailContrat.vue'),

        meta: {
          pageTitle: 'Détail de contrat',
          redirectIfLoggedIn: true,

        },
      },
      {
        path: '/espace-locataire/:id/detail-contrats-achat-vente',
        name: 'espace-locataire.detail-contrats-achat-vente',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-locataire/contrats/DetailContratAchatVente.vue'),

        meta: {
          pageTitle: 'Mes des contrats',
          redirectIfLoggedIn: true,

        },
      },
      // Gestion des factures
      {
        path: '/espace-locataire/factures',
        name: 'espace-locataire.factures',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-locataire/factures/Index.vue'),

        meta: {
          pageTitle: 'Mes factures',
          redirectIfLoggedIn: true,

        },
      },
      // Gestion des factures achat vente
      {
        path: '/espace-locataire/factures-achat-vente',
        name: 'espace-locataire.factures-achat-vente',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-locataire/factures/IndexAchatVente.vue'),

        meta: {
          pageTitle: 'Factures achat-vente',
          redirectIfLoggedIn: true,

        },
      },
      
      {
        path: '/espace-locataire/:id/detail-facture',
        name: 'espace-locataire.detail-facture',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-locataire/factures/DetailContrat.vue'),

        meta: {
          pageTitle: 'Détail de facture',
          redirectIfLoggedIn: true,

        },
      },
      
      // Gestion des factures frais copropriété
      {
        path: '/espace-locataire/frais-de-copropriete',
        name: 'espace-locataire.frais-de-copropriete',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-locataire/factures/FraisDeCopropriete.vue'),
        meta: {
          pageTitle: 'Frais de copropriété',
          redirectIfLoggedIn: true,
        },
      },
      // Gestion des factures frais copropriété
      {
        path: '/espace-locataire/factures-de-copropriete',
        name: 'espace-locataire.facture-de-copropriete',

        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/app/espace-locataire/factures/FactureCopropriete.vue'),
        meta: {
          pageTitle: 'Facture de copropriété',
          redirectIfLoggedIn: true,
        },
      },
    ],
  },
]
